import axios from 'axios';
import { Asset, Booking } from 'models';
import { apiAssets, apiBookings, filterEmptyKeys, loadAbort } from 'utilities';

export const getAssetsList = (
  search: string,
  uri: string,
  page: number,
  limit: number,
  orderBy: string
) => {
  const controller = loadAbort();
  const params = {
    search,
    limit,
    offset: page * limit,
    ordering: orderBy,
  };
  filterEmptyKeys(params);
  return {
    call: axios.get<Booking>(`${apiBookings}${uri && uri + '/'}`, {
      signal: controller.signal,
      params,
    }),
    controller,
  };
};

export const getBookingsAsset = (
  uuid: string,
  search: string,
  limit: number,
  page: number,
  ordering?: string
) => {
  const controller = loadAbort();
  if (ordering === '-end') ordering = '-return_date';
  if (ordering === '-start') ordering = '-start_date';
  if (ordering === '-bookingDate') ordering = '-created_at';
  const params = {
    search,
    limit,
    offset: page * limit,
    ordering,
  };
  filterEmptyKeys(params);
  return {
    call: axios.get(`${apiBookings + uuid}/related_bookings/`, {
      signal: controller.signal,
      params,
    }),
    controller,
  };
};

export const getAssets = () => {
  const controller = loadAbort();
  return {
    call: axios.get<Asset>(apiAssets, { signal: controller.signal }),
    controller,
  };
};

export const getAsset = (uuid: string) => {
  const controller = loadAbort();
  return {
    call: axios.get<Asset>(`${apiBookings + uuid}/`, {
      signal: controller.signal,
    }),
    controller,
  };
};

export const postBooking = (data: any) => {
  const controller = loadAbort();
  return {
    call: axios.post(apiBookings, data, { signal: controller.signal }),
    controller,
  };
};

export const deleteAsset = (uuid: string) => {
  const controller = loadAbort();
  return {
    call: axios.delete(`${apiBookings + uuid}/`, {
      signal: controller.signal,
    }),
    controller,
  };
};
export const putBooking = (uuid: string, data: any) => {
  const controller = loadAbort();
  return {
    call: axios.put(`${apiBookings + uuid}/`, data, {
      signal: controller.signal,
    }),
    controller,
  };
};
export const enableAsset = (uuid: string) => {
  const controller = loadAbort();
  return {
    call: axios.post(`${apiBookings + uuid}/report_available/`, '', {
      signal: controller.signal,
    }),
    controller,
  };
};
export const disableAsset = (uuid: string) => {
  const controller = loadAbort();
  return {
    call: axios.post(`${apiBookings + uuid}/report_unavailable/`, '', {
      signal: controller.signal,
    }),
    controller,
  };
};
