import { getSessionToken } from '../services'

import axios from 'axios'

const removeS3PresignedParams = (url: string) => {
  const urlObj = new URL(url)
  const queryParams = urlObj.searchParams
  queryParams.delete('Key-Pair-Id')
  queryParams.delete('Expires')
  queryParams.delete('Signature')
  const cleanedUrl = `${urlObj.origin}${urlObj.pathname}${
    queryParams.toString() ? `?${queryParams.toString()}` : ''
  }`
  return cleanedUrl
}

const cleanBodyUrl = (body: any) => {
  if (typeof body === 'object') {
    for (const key in body) {
      if (typeof body[key] === 'string' && body[key].includes('?Expires=')) {
        body[key] = removeS3PresignedParams(body[key])
      }
    }
  }
  return body
}

export const PublicPrivateInterceptor = () => {
  axios.interceptors.request.use(function (config) {
    return config
  })

  axios.interceptors.request.use(function (request) {
    if (
      request.url?.includes('login') ||
      request.headers?.Authorization ||
      request.url?.includes('passwordrecovery')
    ) {
      return request
    }
    if (
      request.method === 'post' ||
      request.method === 'put' ||
      request.method === 'patch'
    ) {
      console.log('entro acá')
      request.data = cleanBodyUrl(request.data)
    }
    const token = getSessionToken()
    const newHeaders = {
      Authorization: 'token ' + token,
      'Content-Type': 'application/json',
    }
    request.headers = newHeaders
    return request
  })

  axios.interceptors.response.use(
    response => {
      return response
    },
    function (error) {
      if (error.request.status === 401) {
        window.location.href = '/'
      }
      return Promise.reject(error)
    }
  )
}
