import axios from 'axios';
import { apiCarpooling, filterEmptyKeys, loadAbort } from 'utilities';

export const getCarpoolingData = (
  fromDate: string,
  untilDate: string,
  groupName: string,
  subgroupName: string
) => {
  const controller = loadAbort();
  const params: any = {
    from_date: fromDate,
    until_date: untilDate,
    group_name: groupName,
    subgroup_name: subgroupName,
  };
  filterEmptyKeys(params);
  return {
    call: axios.get(apiCarpooling, {
      signal: controller.signal,
      params: params!,
    }),
    controller,
  };
};
