import axios from 'axios';
import {
  apiStatistics,
  convertToCamelCase,
  filterEmptyKeys,
  loadAbort,
} from 'utilities';

export const getTotalData = (
  challengeUuid: string,
  fromDate: string,
  untilDate: string,
  activityType: string,
  groupName: string,
  subgroupName: string,
  travelType?: string
) => {
  const controller = loadAbort();
  const url = apiStatistics + 'total_data/';
  const params: any = {
    challenge: challengeUuid,
    from_date: fromDate,
    until_date: untilDate,
    activity_type__uuid: activityType,
    group_name: groupName,
    subgroup_name: subgroupName,
    travel_type: travelType,
  };
  filterEmptyKeys(params);
  return {
    call: axios.get(url, {
      signal: controller.signal,
      params,
    }),
    controller,
  };
};

export const getTotalDataByActivities = (
  challengeUuid: string,
  fromDate: string,
  untilDate: string,
  groupName: string,
  subgroupName: string,
  travelType?: string
) => {
  const controller = loadAbort();
  const url = apiStatistics + 'by_activity_type/';
  const params: any = {
    challenge: challengeUuid,
    from_date: fromDate,
    until_date: untilDate,
    group_name: groupName,
    subgroup_name: subgroupName,
    travel_type: travelType,
  };
  filterEmptyKeys(params);
  return {
    call: axios.get(url, {
      signal: controller.signal,
      params,
    }),
    controller,
  };
};

export const getDataLineChart = (
  challengeUuid: string,
  fromDate: string,
  untilDate: string,
  groupBy: string,
  activityType: string,
  groupName: string,
  subgroupName: string,
  travelType?: string
) => {
  const controller = loadAbort();
  const url = apiStatistics + 'line_chart/';
  const params: any = {
    challenge: challengeUuid,
    from_date: fromDate,
    until_date: untilDate,
    group_by: groupBy,
    activity_type__uuid: activityType,
    group_name: groupName,
    subgroup_name: subgroupName,
    travel_type: travelType,
  };
  filterEmptyKeys(params);
  return {
    call: axios.get(url, {
      signal: controller.signal,
      params,
    }),
    controller,
  };
};

export const downloadReport = (companyName: string, date: string) => {
  const companyCamelCase = convertToCamelCase(companyName);
  const controller = loadAbort();
  const url = apiStatistics + 'download_month_report/';
  const downloadUrl = window.URL.createObjectURL(new Blob([]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', `report_${companyCamelCase}_${date}.xlsx`);
  document.body.appendChild(link);

  const call = axios
    .get(url, {
      signal: controller.signal,
      responseType: 'blob',
    })
    .then((response) => {
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.click();
      link.remove();
      return response;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      controller.abort();
    });

  return {
    call: Promise.resolve(call),
    controller,
  };
};
