export const apiGetUser = `${process.env.REACT_APP_BASE_URL}/dashboard/api/userinfo/`;
export const apiLoginUser = `${process.env.REACT_APP_BASE_URL}/api/auth/login/`;
export const apiChallenges = `${process.env.REACT_APP_BASE_URL}/dashboard/api/challenges/`;
export const apiRewards = `${process.env.REACT_APP_BASE_URL}/dashboard/api/rewards/`;
export const apiDelimitedAreas = `${process.env.REACT_APP_BASE_URL}/dashboard/api/delimited-areas/`;
export const apiGroups = `${process.env.REACT_APP_BASE_URL}/dashboard/api/groups/`;
export const apiSupGroups = `${process.env.REACT_APP_BASE_URL}/dashboard/api/subgroups/`;
export const apiGetActivities = `${process.env.REACT_APP_BASE_URL}/dashboard/api/activity-types/?limit=20&offset=0`;
export const apiImages = `${process.env.REACT_APP_BASE_URL}/dashboard/api/images/`;
export const apiCompanyAdmin = `${process.env.REACT_APP_BASE_URL}/dashboard/api/companyadmininfo/`;
export const apiCompanyViews = `${process.env.REACT_APP_BASE_URL}/dashboard/api/company/`;
export const apiCompanyOptions = `${process.env.REACT_APP_BASE_URL}/dashboard/api/company/options/`;
export const apiCompanyConfig = `${process.env.REACT_APP_BASE_URL}/dashboard/api/company/config/`;
export const apiCompanyStyle = `${process.env.REACT_APP_BASE_URL}/dashboard/api/style/`;
export const apiPrizes = `${process.env.REACT_APP_BASE_URL}/dashboard/api/prizes/`;
export const apiUniquePrizes = `${process.env.REACT_APP_BASE_URL}/dashboard/api/prizes/unique/`;
export const apiRanking = `${process.env.REACT_APP_BASE_URL}/dashboard/api/ranking/`;
export const apiPermissions = `${process.env.REACT_APP_BASE_URL}/dashboard/api/permissions/`;
export const apiGetUsers = `${process.env.REACT_APP_BASE_URL}/dashboard/api/users/`;
export const apiStatistics = `${process.env.REACT_APP_BASE_URL}/dashboard/api/statistics/`;
export const apiNews = `${process.env.REACT_APP_BASE_URL}/dashboard/api/blog/`;
export const apiBookings = `${process.env.REACT_APP_BASE_URL}/dashboard/api/booking/assets/`;
export const apiAssets = `${process.env.REACT_APP_BASE_URL}/dashboard/api/booking/asset_types/`;
export const apiCarpooling = `${process.env.REACT_APP_BASE_URL}/dashboard/api/trips/`;
export const apiRecoveryPass = `${process.env.REACT_APP_BASE_URL}/mobile/api/passwordrecovery/`;
export const apiC30DIB = `${process.env.REACT_APP_BASE_URL}/dashboard/api/multicompany_data/`;
