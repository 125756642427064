import axios from 'axios';
import { faqsUrl, loadAbort } from 'utilities';

export const getSheet = (section: string) => {
  const sectionId = faqsUrl.find((item) => item.section === section)?.sectionId;
  const controller = loadAbort();
  const url = `https://docs.google.com/spreadsheets/d/e/${sectionId}/pub?output=tsv`;
  return {
    call: axios.get(url, { signal: controller.signal }),
    controller,
  };
};

export const getSheetTest = (url: string) => {
  const controller = loadAbort();
  return {
    call: axios.get(url, { signal: controller.signal }),
    controller,
  };
};
