import axios from 'axios';
import { apiGetActivities, loadAbort } from 'utilities';

export const getActivities = () => {
  const controller = loadAbort();
  return {
    call: axios.get(apiGetActivities, { signal: controller.signal }),
    controller,
  };
};
