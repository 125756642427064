import { useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import { AxiosCall } from 'models';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const useFetchAndLoad = () => {
  const { t } = useTranslation('errors');
  const [loading, setLoading] = useState(false);
  let controller: AbortController;

  const callEndpoint = async (axiosCall: AxiosCall<any>) => {
    if (axiosCall.controller) controller = axiosCall.controller;
    setLoading(true);
    let result = {} as AxiosResponse<any>;
    try {
      result = await axiosCall.call;
    } catch (error: any) {
      if (error.response?.data && error.response.status < 500) {
        const err = error.response.data;
        for (const prop in err) {
          toast.error(`${t(prop)}: ${err[prop]}`);
        }
        setLoading(false);
        throw error;
      }
      if (error.response?.data && error.response.status === 500) {
        throw error;
      }
    }
    setLoading(false);
    return result;
  };

  const cancelEndpoint = () => {
    setLoading(false);
    controller && controller.abort();
  };

  useEffect(() => {
    return () => {
      cancelEndpoint();
    };
  }, []);

  return { loading, callEndpoint };
};
