import axios from 'axios';
import {
  apiCompanyAdmin,
  apiCompanyConfig,
  apiCompanyOptions,
  apiCompanyStyle,
  apiCompanyViews,
  loadAbort,
} from 'utilities';

const controller = loadAbort();

export const getCompanyAdmin = () => {
  return {
    call: axios.get(apiCompanyAdmin, { signal: controller.signal }),
    controller,
  };
};
export const getCompanyInfo = () => {
  return {
    call: axios.get(apiCompanyViews, { signal: controller.signal }),
    controller,
  };
};
export const getCompanyOptions = () => {
  return {
    call: axios.get(apiCompanyOptions, { signal: controller.signal }),
    controller,
  };
};
export const patchCompanyConfig = (data: any) => {
  return {
    call: axios.patch(apiCompanyConfig, data, { signal: controller.signal }),
    controller,
  };
};
export const getCompanyStyle = () => {
  return {
    call: axios.get(apiCompanyStyle, { signal: controller.signal }),
    controller,
  };
};
export const postCompanyStyle = (data: any) => {
  return {
    call: axios.post(apiCompanyStyle, data, { signal: controller.signal }),
    controller,
  };
};
