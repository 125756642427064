import axios from 'axios';
import { apiGetUsers, filterEmptyKeys, loadAbort } from 'utilities';

export const getUsers = (
  page: number,
  limit: number,
  cluster: string,
  orderBy: string,
  search: string
) => {
  const controller = loadAbort();
  if (orderBy === '-inscription') orderBy = '-date_joined';
  let group = '';
  let subgroup = '';
  const detectData = cluster.split('_');
  if (detectData[0] === 'group') {
    group = detectData[1];
  } else {
    if (detectData[0] === 'subgroup') subgroup = detectData[1];
  }
  const params = {
    offset: page * limit,
    limit,
    subgroups__group__uuid: group,
    subgroups__uuid: subgroup,
    ordering: orderBy,
    search,
  };
  filterEmptyKeys(params);
  return {
    call: axios.get(apiGetUsers, { signal: controller.signal, params }),
    controller,
  };
};
