import axios from 'axios';
import { Reward } from 'models';
import { apiRewards, filterEmptyKeys, loadAbort } from 'utilities';

export const getRewards = (
  search: string,
  uri: string,
  page: number,
  limit: number,
  orderBy: string
) => {
  const controller = loadAbort();
  if (orderBy === '-start') orderBy = '-active_from';
  if (orderBy === '-end') orderBy = '-valid_until';
  if (orderBy === '-codes') orderBy = '-unclaimed';
  const params = {
    limit,
    offset: limit * page,
    search,
    ordering: orderBy,
  };
  filterEmptyKeys(params);
  return {
    call: axios.get(`${apiRewards}${uri && uri + '/'}`, {
      signal: controller.signal,
      params,
    }),
    controller,
  };
};

export const getReward = (uuid: string) => {
  const controller = loadAbort();
  return {
    call: axios.get(apiRewards + uuid, { signal: controller.signal }),
    controller,
  };
};

export const postReward = (data: {}) => {
  const controller = loadAbort();
  return {
    call: axios.post<Reward>(apiRewards, data, { signal: controller.signal }),
    controller,
  };
};

export const putReward = (uuid: string, data: {}) => {
  const controller = loadAbort();
  return {
    call: axios.put<Reward>(`${apiRewards}${uuid}/`, data, {
      signal: controller.signal,
    }),
    controller,
  };
};

export const deleteReward = (uuid: string) => {
  const controller = loadAbort();
  return {
    call: axios.delete(`${apiRewards}${uuid}/`, { signal: controller.signal }),
    controller,
  };
};

export const getRewardsCodes = (
  uuid: string,
  search: string,
  page: number,
  limit: number,
  orderBy: string
) => {
  const controller = loadAbort();
  const listAsc = ['code'];
  if (!listAsc.includes(orderBy) && orderBy) orderBy = '-' + orderBy;
  if (orderBy === 'code') orderBy = 'claim_code';
  if (orderBy === '-claimedAt') orderBy = '-claimed_at';
  const params = {
    limit,
    offset: limit * page,
    search,
    ordering: orderBy,
  };
  filterEmptyKeys(params);
  return {
    call: axios.get(`${apiRewards}${uuid}/claimed_rewards/`, {
      signal: controller.signal,
      params,
    }),
    controller,
  };
};
